/** --------------------------------------------------
* 
*   components
*   CONTENT FILTER
*
*  -------------------------------------------------- */

(function($){

  Drupal.behaviors.contentFilterModals = {
    attach: function (context, settings) {

      // find all "news" links inside a "content filter" block
      // and add "#popup" to the links:

      var $contentFilterNewsLinks = $(document).find('#wcm-search-content-filter .node-news-item.node-teaser .field-name-field-image a').not('[href*="#popup"]');
      $contentFilterNewsLinks.each(function(index, element) {
        var $contentFilterNewsLink = $(element);

        var url = $contentFilterNewsLink.attr('href');
        var newUrl = url + '#popup';
        $contentFilterNewsLink.attr('href', newUrl);
      });
    }
  };
})(jQuery);
