/** --------------------------------------------------
*   LIVE EVENT
*  -------------------------------------------------- */

(function ($) {

  $(document).ready(function () {

    // setup "live" menu item:
    setupLiveMenuItem();
  });

  function setupLiveMenuItem() {
    if (eventIsLive()) {

      // insert an extra item into the main
      // navigation list:
      var liveItem = $('<li class="item-list__live-menu-item"><div class="live-nav-item"><div class="live-nav-item__icon-zone"><div class="live-nav-item__icon"></div></div><div class="live-nav-item__text"> ' + Drupal.t('Live') + ' </div></div></li>');
      var $mainNavList = $('.extended-header-menu-wrapper > .item-list > ul');
      if ($mainNavList.length > 0) {
        $mainNavList.prepend(liveItem);
      }
    }
  }

  function eventIsLive() {
    var isLive = false;

    if ($('body').hasClass('currently-live')) {
      isLive = true;
    }

    return isLive;
  }
})(jQuery);
