/** --------------------------------------------------
* 
*   helpers
*   UTILS
*
*  -------------------------------------------------- */

(function ($, duo, undefined) {
  "use strict";

  /**
   * Helper functions
   * @class Utils
   */
  class Utils {

    /**
     * Creates an instance of Utils
     */
    constructor() {
    } /* constructor */

    /**
     * Cross-browser helper for the transitionend-event
     * @readonly
     * @static
     */
    static get transitionEvent() {
      var t;
      var el = document.createElement('fakeelement');
      var transitions = {
        'transition': 'transitionend',
        'OTransition': 'oTransitionEnd',
        'MozTransition': 'transitionend',
        'WebkitTransition': 'webkitTransitionEnd'
      };
      for (t in transitions) {
        if (el.style[t] !== undefined) {
          return transitions[t];
        }
      }
    } /* transitionEvent */

    /**
     * Generate a unique ID
     * -> returns a value that can be used as a unique ID
     * @static
     */
    static get uniqueId() {
      return Math.random().toString(36).substr(2, 16);
    } /* uniqueId */

    /**
     * Check if a value is a number
     * @returns {Boolean} (true if the value is a number)
     * @static
     */
    static isNumber(value) {
      if (value === undefined) {
        return false;
      }

      return !isNaN(parseFloat(value)) && isFinite(value);
    } /* isNumber */

    /**
     * Check if a value is an integer
     * @returns {Boolean} (true if the value is an integer)
     * @static
     */
    static isInt(value) {
      var x;
      if (isNaN(value)) {
        return false;
      }
      x = parseFloat(value);
      return (x | 0) === x;
    } /* isInt */

    /**
     * Get the number of decimals
     * @returns {Number} number of decimals
     * @static
     */
    static countDecimals(value) {
      if ((value % 1) != 0) {
        return value.toString().split(".")[1].length;
      } else {
        return 0;
      }
    } /* countDecimals */

    /**
     * Check click or keypress
     * -> use this function in the click/keypress event of an element
     *    with a role of "button", to make sure the event can be triggered
     *    using the enter-key and the spacebar
     * @static
     */
    static a11yClick(event) {
      if (event.type === 'click') {
        return true;
      } else if (event.type === 'keypress') {
        var code = event.charCode || event.keyCode;
        if ((code === 32) || (code === 13)) {
          return true;
        }
      } else {
        return false;
      }
    } /* a11yClick */

    /**
    * Detect if the browser supports scroll-behavior: smooth
    * @returns {Boolean} true if browser supports smooth scroll
    * @static
    */
    static supportsSmoothScroll() {
      const body = document.body;
      const scrollSave = body.style.scrollBehavior;
      body.style.scrollBehavior = 'smooth';
      const hasSmooth = getComputedStyle(body).scrollBehavior === 'smooth';
      body.style.scrollBehavior = scrollSave;
      return hasSmooth;
    }

    /**
    * Polyfill for browsers that don't support scrollTo with smooth behavior.
    * @static
    */
    static smoothScrollPolyfill(node, key, target) {
      const startTime = Date.now();
      const offset = node[key];
      const gap = target - offset;
      const duration = 1000;
      let interrupt = false;

      const step = () => {
        const elapsed = Date.now() - startTime;
        const percentage = elapsed / duration;

        if (interrupt) {
          return;
        }

        if (percentage > 1) {
          cleanup();
          return;
        }

        node[key] = this.easingOutQuint(0, elapsed, offset, gap, duration);
        requestAnimationFrame(step);
      };

      const cancel = () => {
        interrupt = true;
        cleanup();
      };

      const cleanup = () => {
        node.removeEventListener('wheel', cancel);
        node.removeEventListener('touchstart', cancel);
      };

      node.addEventListener('wheel', cancel, { passive: true });
      node.addEventListener('touchstart', cancel, { passive: true });

      step();

      return cancel;
    }

    /**
    * Smooth scroll: test to determine if polyfill needs to be called.
    * @static
    */
    static get testSupportsSmoothScroll() {
      const body = document.body;
      const scrollSave = body.style.scrollBehavior;
      body.style.scrollBehavior = 'smooth';
      const hasSmooth = getComputedStyle(body).scrollBehavior === 'smooth';
      body.style.scrollBehavior = scrollSave;
      return hasSmooth;
    }

    /**
    * Custom smoothScroll implementation with a polyfill fallback
    * in case the browser has no native support.
    * @static
    */
    static smoothScroll(node, topOrLeft, horizontal, native) {
      if (native) {
        return node.scrollTo({
          [horizontal ? 'left' : 'top']: topOrLeft,
          behavior: 'smooth'
        });
      } else {
        return this.smoothScrollPolyfill(node, horizontal ? 'scrollLeft' : 'scrollTop', topOrLeft);
      }
    }

    /**
    * Returns a value along an easing bezier curve.
    */
    static easingOutQuint(x, t, b, c, d) {
      return c * ((t = t / d - 1) * t * t * t * t + 1) + b;
    }

    /**
    * Returns true if the device has touch support.
    */
    static hasTouchSupport() {
      return ( 'ontouchstart' in window ) ||
        ( navigator.maxTouchPoints > 0 ) ||
        ( navigator.msMaxTouchPoints > 0 );
    }

    /**
    * Returns a function that, as long as it continues to be invoked,
        * will not be triggered. The function will be called after it stops
        * being called for N ms. If 'immediate' is passed, trigger the function
        * on the leading edge instead of the trailing.
    */
    static debounce(func, wait, immediate) {
      var timeout;

      return function () {
        var context = this, args = arguments;
        var later = function () {
          timeout = null;
          if (!immediate) func.apply(context, args);
        };

        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
      };
    }

  } /* Utils */

  duo.Utils = Utils;

})(jQuery, window.duo = window.duo || {});
