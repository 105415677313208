/** --------------------------------------------------
*   SMOOTH SCROLL
*  -------------------------------------------------- */

(function($){
  Drupal.behaviors.smoothScroll = {
    attach: function (context, settings) {
      var _self = this;
      _self.initSmoothScroll();
    },

    initSmoothScroll: function () {
      if ($('.recipe-parts-navigation').length === 0) {
        return;
      }

      var items = $('.recipe-parts-navigation a');

      items.once('once-click-smooth-scroll').bind('click', function (e) {
        e.preventDefault();

        var link = $(this);
        var scrollTarget = link.attr('href').split('#')[1];
        var offset = 20;

        if ($('body').hasClass('logged-in')) {
          offset = 50;
        }
        
        $("html, body").animate({ 
          scrollTop: $('#' + scrollTarget).offset().top - offset
        }, 300);
      });
    }
  };
})(jQuery);
