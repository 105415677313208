/** --------------------------------------------------
*   PARDOT IFRAMES
*  -------------------------------------------------- */

(function($){

  $(document).ready(function() {

    // setup pardot iframes:
    setupPardotIframes();
  });

  function setupPardotIframes() {

    // find all iframe-elements (within the paragraph content)
    // that show a pardot form:

    var $pardotIframes= $(document).find('.paragraphs-items iframe[src*="pardot.com"]');
    $pardotIframes.each(function(index, element) {
      var $pardotIframe = $(element);
      
      // get parent-element:
      var $iframeHolder = $pardotIframe.parents('.field-iframe');
      if($iframeHolder.length > 0) {

        // overwrite height:
        $iframeHolder.css('padding-bottom', '180%');
      }
    });
  }
})(jQuery);
