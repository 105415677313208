/** --------------------------------------------------
* 
*   pages
*   VOTE PAGE
*
*  -------------------------------------------------- */

(function ($) {

  $(document).ready(function () {
    if (isVotePage()) {

      // setup vote form:
      setupVoteForm();

      // setup thank-you message:
      setupThankyouMessage();
    }
  });

  function setupVoteForm() {

    // if the vote form is shown, we should change the page
    // styling to simulate an "overlay" effect;

    var $voteConfirmationForm = $(document).find('form.confirmation');
    if($voteConfirmationForm.length > 0) {

      // add extra markup to the form:
      var $formContent = $voteConfirmationForm.find('> div');
      if($formContent.length > 0) {
        var $overlay = $('<div class="vote-overlay"><div class="vote-overlay__inner"><div class="vote-overlay__content"><div class="vote-overlay__content-inner"></div></div></div></div>');
        $formContent.wrap($overlay);

        // add a html-class:
        $('html').addClass('js-vote-overlay-shown');
      }
    }
  }

  function setupThankyouMessage() {

    // if the "vote - thank you" message is shown, we should change the page
    // styling to simulate an "overlay" effect;

    var $thankyouMessage = $(document).find('.wcm-votes-vote-confirm-form-info-thank-you');
    if($thankyouMessage.length > 0) {

      // add extra markup to the form:
      var $overlay = $('<div class="vote-overlay"><div class="vote-overlay__inner"><div class="vote-overlay__content"><div class="vote-overlay__content-inner"></div></div></div></div>');
      $thankyouMessage.wrap($overlay);

      // add a html-class:
      $('html').addClass('js-vote-overlay-shown');
    }
  }

  function isVotePage() {
    var isVotePage = false;

    if ($('body').hasClass('page-wcm-votes-vote-for-showpiece')) {
      isVotePage = true;
    }

    return isVotePage;
  }
})(jQuery);
