/** --------------------------------------------------
* 
*   pages
*   GROUPED PAGE
*
*  -------------------------------------------------- */

(function ($) {

  var groupMaxHeight = 0;
  var groupItemheight = 0;
  var groupedContext = 0;

  $(window).resize(function () {
    setGroupItemHeight();
  });

  Drupal.behaviors.groupedpage = {
    attach: function (context, settings) {

      groupedContext = context;

      var activeGroupItemIndex = $('.group-items-wrapper .field-name-field-group-items .group-item-wrapper').index();

      // Set slider
      if ($('.group-items-wrapper').length > 0) {

        // Flexslider
        var slider = $('.group-items-wrapper')
          .flexslider({
            selector: '.field-name-field-group-items > .group-item-wrapper',
            animation: "slide",
            controlNav: false,
            directionNav: true,
            animationLoop: false,
            slideshow: false,
            startAt: activeGroupItemIndex,
            itemWidth: 210,
            itemMargin: 30,
            maxItems: 5,
            touch: true,
            start: function () {
              setGroupItemHeight();
            }
          });
      }
    }
  };

  // Set group item height
  function setGroupItemHeight() {

    // When images are loaded, set the height
    if (groupedContext != 0) {
      $(groupedContext).imagesLoaded().then(function () {
        setEachGroupItemHeight();
      });

    }

  }

  function setEachGroupItemHeight() {

    // remove height
    $('.node-group .field-name-field-group-items .group-item-wrapper').css('height', '');

    // check max height
    $('.node-group .field-name-field-group-items .group-item-wrapper').each(function () {
      groupItemheight = $(this).outerHeight();

      if (groupItemheight > groupMaxHeight) {
        groupMaxHeight = groupItemheight;
      }

    });

    // set height
    $('.node-group .field-name-field-group-items .group-item-wrapper').css('height', groupMaxHeight);
  }

  // Images loaded
  $.fn.imagesLoaded = function () {

    // Edit: in strict mode, the var keyword is needed
    var $imgs = this.find('img[src!=""]');
    // if there's no images, just return an already resolved promise
    if (!$imgs.length) { return $.Deferred().resolve().promise(); }

    // for each image, add a deferred object to the array which resolves when the image is loaded (or if loading fails)
    var dfds = [];
    $imgs.each(function () {

      var dfd = $.Deferred();
      dfds.push(dfd);
      var img = new Image();
      img.onload = function () { dfd.resolve(); };
      img.onerror = function () { dfd.resolve(); };
      img.src = this.src;

    });

    // return a master promise object which will resolve when all the deferred objects have resolved
    // IE - when all the images are loaded
    return $.when.apply($, dfds);
  };
})(jQuery);
