/** --------------------------------------------------
*   SLICK SLIDER
*  -------------------------------------------------- */

(function ($) {
  Drupal.behaviors.carousel = {
    attach: function (context, settings) {
      this.keyDates();
    },

    keyDates: function () {

      var container = $('.key-dates-container').find('.slides');

      container.slick({
        slide: '.node',
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              infinite: false,
              slidesToShow: 3,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 600,
            settings: {
              infinite: false,
              slidesToShow: 2,
              slidesToScroll: 1,
            }
          }
        ]
      });

    }
  };
})(jQuery);
