/** --------------------------------------------------
* 
*   components
*   VOTE LINKS
*
*  -------------------------------------------------- */

(function($){

  Drupal.behaviors.voteLinks = {
    attach: function (context, settings) {

      /* setup vote links: */
      setupVoteLinks();
    }
  };

  function setupVoteLinks() {

    /*
    * we need to add a target to the vote-links,
    * to prevent them opening inside the modal/iframe
    * view 
    */

    // add "target=_parent" to links:
    var $voteLinks = $('.wcm-votes-vote-button-link');
    $voteLinks.each(function(index, element) {
      $(element).attr('target', '_parent');
    });
  }
})(jQuery);
