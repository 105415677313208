/** --------------------------------------------------
* 
*   pages
*   COMPETITION HOMEPAGE
*
*  -------------------------------------------------- */

(function ($) {

  $(document).ready(function () {
    if (isCompetitionHomepage()) {

      // setup links:
      setupPopupLinks();
    }
  });

  function setupPopupLinks() {

    // add "#popup" to links:
    var $videosView = $('.competition-homepage__video-zone');
    if ($videosView.length > 0) {
      var $linksThatShouldPopup = $videosView.find('.video-basic-teaser__block-link');
      $linksThatShouldPopup.each(function (index, element) {
        var linkHref = $(element).attr('href');
        var newHref = linkHref;
        if (linkHref.indexOf("#popup") === -1) {
          newHref += '#popup';
        }
        $(element).attr('href', newHref);
      });
    }
  }

  function isCompetitionHomepage() {
    var isCompetitionPage = false;

    if ($('body').hasClass('node-type-competition-homepage')) {
      isCompetitionPage = true;
    }

    return isCompetitionPage;
  }
})(jQuery);
