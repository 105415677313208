/** --------------------------------------------------
* 
*   pages
*   RESULTS OVERVIEW PAGE
*
*  -------------------------------------------------- */

(function ($) {

  $(document).ready(function () {
    if (isResultsOverviewPage()) {

      // setup results lists:
      setupResultsLists();

      // setup animated scroll:
      setupAnimatedScroll();
    }
  });

  function setupResultsLists() {

    /*
     * we need to split the results list into
     * two separate results lists
     */

    var $resultBlock = $('.results-overview__top-block__results');
    var $resultList = $('.results-overview__top-block__results-list');

    if ($resultBlock.length > 0 && $resultList.length > 0) {

      var $resultListItems = $resultList.find('.results-overview__top-block__results-list__item');
      var resultItemLength = $resultListItems.length;
      if (resultItemLength > 3) {
        var firstListItemCount = Math.ceil(resultItemLength / 2);

        var firstListItems = [];
        var secondListItems = [];
        $resultListItems.each(function (index, element) {
          if (index < firstListItemCount) {
            firstListItems.push($(element));
          } else {
            secondListItems.push($(element));
          }
        });

        var listContainer = $('<div class="results-overview__top-block__js-list-container"></div>');

        var firstList = $('<ol class="results-overview__top-block__js-list results-overview__top-block__js-list--1"></ol>');
        $.each(firstListItems, function (index, value) {
          firstList.append(value);
        });
        listContainer.append(firstList);

        var secondList = $('<ol class="results-overview__top-block__js-list results-overview__top-block__js-list--2"></ol>');
        $.each(secondListItems, function (index, value) {
          secondList.append(value);
        });
        listContainer.append(secondList);

        $resultBlock.append(listContainer);
        $('.results-overview__top-block__results-list').remove();
        $resultBlock.addClass('js-processed');
      }
    }
  }

  function setupAnimatedScroll() {
    $(document).on('click', '.result-anchor-link', function (e) {
      if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');

        if (target.length) {
          $('html, body').animate({
            scrollTop: target.offset().top
          }, 1000);

          target.focus();
          if (target.is(":focus")) {
            return false;
          } else {
            target.attr('tabindex', '-1');
            target.focus();
          }

          return false;
        }
      }
    });
  }

  function isResultsOverviewPage() {
    var isResultsPage = false;

    if ($('body').hasClass('node-type-results-overview')) {
      isResultsPage = true;
    }

    return isResultsPage;
  }
})(jQuery);
