/** --------------------------------------------------
*   FLEXSLIDER
*  -------------------------------------------------- */

(function ($) {
  Drupal.behaviors.flexSlider = {
    attach: function () {
      this.initSponsorSlider();
    },

    initSponsorSlider: function () {
      var _self = this;
      var start = 0;

      var sponsorSlider = $('.our-sponsors-wrapper').flexslider({
        selector: '.slides > div',
        startAt: start,
        slideshowSpeed: _self.getSponsorSlideSpeed(start),
        controlNav: false,
        after: function (slider) {
          slider.stop();
          slider.vars.slideshowSpeed = _self.getSponsorSlideSpeed(slider.currentSlide);
          slider.play();
        }
      }).data('flexslider');

      $('.our-sponsors-wrapper').on('inview', function (event, isInView) {
        if (isInView) {
          sponsorSlider.flexAnimate(start, start);
        }
        else {
          sponsorSlider.flexAnimate(start - 1, stop);
        }
      });
    },

    getSponsorSlideSpeed: function (index) {
      switch (index) {
        case 0:
          return 4000;
        case 1:
          return 3000;
        case 2:
          return 2000;
        case 3:
          return 1500;
      }

      return 1500;
    }
  };
})(jQuery);
