/** --------------------------------------------------
*   ASIDE NAV
*   -------------------------------------------------- */

(function ($) {
  var BtnResp = $('[data-role="responsive-button"]');
  var menuWrapper = $('[data-role="responsive-aside"]');
  var respHtmlClass = 'show-sidebar-nav';

  Drupal.behaviors.asideNav = {
    attach: function (context, settings) {

      // When the menu button is clicked
      $('#btn-resp').click(function () {
        $('html').toggleClass(respHtmlClass);
        return false;
      });

      // When the menu is open and the page is clicked
      $(document).click(function (e) {
        if ($('html').hasClass(respHtmlClass)) {

          // Check if click was triggered on or within #main_nav
          if ($(e.target).closest("#main-nav").length <= 0 && $(e.target).closest("#btn-resp").length <= 0) {
            $('html').removeClass(respHtmlClass);
          }
        }
      });
    }
  };
})(jQuery);
