/** --------------------------------------------------
*   THEME SCRIPT
*  -------------------------------------------------- */

(function ($) {

  var size = false;
  var isResponsive = false;

  Drupal.behaviors.behaviorName = {
    attach: function (context, settings) {
    }
  };

  $(document).ready(function () {

    // setup keydates:
    keyDates();
  });

  $(window).load(function () {
    getMediaquery();
  });

  $(window).resize(function () {
    getMediaquery();
  });

  function getMediaquery() {
    // get the current css media query:
    if (window.getComputedStyle) {
      size = window.getComputedStyle(document.body, ':after').getPropertyValue('content');
      size = size.replace(/["']/g, "");

      isResponsive = window.getComputedStyle(document.body, ':before').getPropertyValue('content');
      isResponsive = isResponsive.replace(/["']/g, "");
      if (isResponsive === "menu") {
        isResponsive = true;
      } else {
        isResponsive = false;
      }
    }
  }

  function keyDates() {
    $('.key-dates-title').on('click keypress', function (e) {
      if (a11yClick(event)) {
        $(this).parent().toggleClass('js-key-dates-active');
        e.preventDefault();
      }
    });
  }

  function a11yClick(event) {
    if (event.type === 'click') {
      return true;
    } else if (event.type === 'keypress') {
      var code = event.charCode || event.keyCode;
      if ((code === 32) || (code === 13)) {
        return true;
      }
    } else {
      return false;
    }
  }
})(jQuery);
