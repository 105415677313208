/** --------------------------------------------------
* 
*   components
*   HEADER
*
*  -------------------------------------------------- */

(function($){

  $(document).ready(function() {

    // setup mobile navigation:
    setupMobileNavigation();

  });

  function setupMobileNavigation() {

    // copy the "meet the finalists" toggle button
    // from "region-header-extra" to the main navigation
    // block (in order to show it in the mobile navigation)

    var $finalistsToggle = $(document).find('.region-header-extra .meet-the-finalists-link');
    var $mainnavBlock = $(document).find('.extended-header-menu-wrapper');

    if($finalistsToggle.length > 0 && $mainnavBlock.length > 0) {
      var $finalistsToggleClone = $finalistsToggle.clone();
      var $buttonWrapper = $('<div class="nav-meetfinalists-holder"></div>');
      $buttonWrapper.append($finalistsToggleClone);
      $mainnavBlock.append($buttonWrapper);
    }
  }
})(jQuery);
