/** --------------------------------------------------
* 
*   components
*   AGENDA BLOCK
*
*  -------------------------------------------------- */

(function($){

  $(document).ready(function() {

    // setup day switcher:
    setupDaySwitcher();

  });

  function setupDaySwitcher() {

    /* check all agenda items and add markup */
    /* ************************************* */

    $('.paragraphs-item-agenda-item').each(function(index, element) {

      // check if the item has a top-zone, if not, add it:
      var $topZone = $(element).find('.agenda-item__top-container');
      if($topZone.length === 0) {
        var $innerBlock = $(element).find('.agenda-item__inner');
        if($innerBlock.length > 0) {
          var newTopZone = $('<div class="agenda-item__top-container"></div>');
          $innerBlock.prepend(newTopZone);
        }
      }

    });

    /* create buttons for each agenda-day */
    /* ********************************** */

    var $agendaGroupContainer = $('.agenda-group-container');

    // container for the buttons:
    var switcherZone = $('<div class="agenda-group__switcher"></div>');
    var switcherZoneInner = $('<div class="agenda-group__switcher-inner"></div>');
    var switcherHeader = $('<div class="agenda-group__switcher-header">' + Drupal.t('Livestream schedule') + '</div>');
    var switcherFooter= $('<div class="agenda-group__switcher-footer"><span style="visibility: hidden;">' + Drupal.t('Livestream schedule') + '</span></div>');
    switcherZone.append(switcherHeader);
    switcherZone.append(switcherZoneInner);
    switcherZone.append(switcherFooter);

    // loop through all the groups and add buttons:
    $('.agenda-group__content').find('.paragraphs-item-agenda-group').each(function(index, element) {
      var $groupBlock = $(element);

      // -> get the text for the button:

      var timeString = '';
      var timeElement = $groupBlock.find('.agenda-group__date');
      if(timeElement.length > 0) {
        timeString = timeElement.text();
      }

      var nameString = '';
      var nameElement = $groupBlock.find('.agenda-group__title');
      if(nameElement.length > 0) {
        nameString = nameElement.text();
      }

      // -> create a unique ID for each group:
      var id = 'group' + getUniqueId();
      $groupBlock.attr('id', id);

      // -> give active items the "js-active" class:
      var activeButtonClass = '';
      if($groupBlock.hasClass('current-item')) {
        $groupBlock.addClass('js-active');
        activeButtonClass = ' js-active';
        $('body').addClass('js-agenda-day-active');
      }

      // -> button-text:
      var buttonText = '<span class="agenda-group__switchbutton__title">' + nameString + '</span>' + '' + '<span class="agenda-group__switchbutton__time">' + timeString + '</span>';

      // -> add button to the zone:
      var $newButton = $('<div class="agenda-group__switchbutton-container"><button class="agenda-group__switchbutton' + activeButtonClass + '" aria-controls="' + id + '">' + buttonText + '</button></div>');
      switcherZoneInner.append($newButton);
    });
    
    // add the button container to the page:
    $agendaGroupContainer.prepend(switcherZone);

    /* initialize flickity slider */
    /* ************************** */

    // loop through all the groups:
    $('.agenda-group__items').each(function(index, element) {

      // check if we have an active agenda-item:
      // -> we use this as the start-index of the slider
      var startIndex = 0;
      var activeAgendaItemFound = false;
      $(element).find('.agenda-group__item').each(function(index, element) {
        var $agendaGroupItem = $(element);
        var $activeBlock = $agendaGroupItem.find('.paragraphs-item-agenda-item.current-item');
        if($activeBlock.length > 0) {
          startIndex = index;
          activeAgendaItemFound = true;
        }
      });
      // -> if we don't have an active agenda-item, we need to
      //    go through the date-times of all the items:
      if(!activeAgendaItemFound) {
        // --> find the one with the closest start time:
        var smallestTimeDifference = 999999999;
        var currentChosenIndex = 0;
        var currentTimestamp = Math.floor(new Date().getTime() / 1000);
        $(element).find('.agenda-group__item').each(function(index, element) {
          var $currentAgendaItem = $(element).find('.paragraphs-item-agenda-item');
          if($currentAgendaItem.length > 0) {
            var fromTimestamp = $currentAgendaItem.data('from-timestamp');
            if(fromTimestamp > currentTimestamp) {
              var timeDifference = fromTimestamp - currentTimestamp;
              if(timeDifference < smallestTimeDifference) {
                smallestTimeDifference = timeDifference;
                currentChosenIndex = index;
              }
            }
          }
        });
        if(currentChosenIndex > 0) {
          startIndex = currentChosenIndex;
        }
      }

      // add a "ready" class to the slider:
      // -> this needs to be defined before flickity is initalized
      $(element).on('ready.flickity', function() {
        $(element).addClass('js-flickity-ready');
      });

      // initialize flickity:
      var groupFlickity = $(element).flickity({
        // -> options
        cellSelector: '.agenda-group__item',
        cellAlign: 'left',
        contain: true,
        prevNextButtons: false,
        pageDots: false,
        freeScroll: false,
        initialIndex: startIndex,
        percentPosition: false,
        setGallerySize: false,
        resize: true,
        draggable: true
      });

      // add class when dragging:
      $(element).on('dragStart.flickity', function(event, pointer) {
        $(this).addClass('is-dragging');
      });
      $(element).on('dragEnd.flickity', function(event, pointer) {
        $(this).removeClass('is-dragging');
      });

      // flickity click events:
      var flickity = $(element).data('flickity');
      $(element).on( 'click', '.meet-the-finalists-link', function( event ) {

        if ( event.currentTarget != flickity.selectedElement ) {
          event.preventDefault();
        }

      });

      $(element).on('staticClick.flickity', function(event, pointer, cellElement, cellIndex) {
        event.preventDefault();

        // detect specific items/links:
        var tagName = pointer.target;
        if($(tagName).hasClass('meet-the-finalists-link')) {
          window.openMeetTheFinalistsBlockFunction($(tagName));
        }

        // do nothing if cell was not clicked:
        if (!cellElement) {
          return;
        }

      });

    });

    /* slider resize */
    /* ------------- */

    resizeAgendaGroups();
    var resizeTimer;
    $(window).on('resize', function(e) {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(function() {
        resizeAgendaGroups();
      }, 250);
    });

    function resizeAgendaGroups() {
      $('.agenda-group__items').each(function(index, element) {
        $(element).height('');
        var highest = 0;
        var items = $(element).find('.agenda-group__item');
        items.each(function(itemIndex, itemElement) {
          $(itemElement).height('');
        });

        items.each(function(itemIndex, itemElement) {
          var itemHeight = $(itemElement).outerHeight();
          if(itemHeight > highest) {
            highest = itemHeight;
          }
        });
        $(element).height(highest);

        items.each(function(itemIndex, itemElement) {
          $(itemElement).height(highest);
        });
      });
    }

    /* switch day */
    /* ********** */

    $(document).on('click', '.agenda-group__switchbutton', function(e) {

      // close all open blocks:
      $('.paragraphs-item-agenda-group').each(function(index, element) {
        $(element).removeClass('js-active');
      });
      // also remove class from the buttons:
      $('.agenda-group__switchbutton').each(function(index, element) {
        $(element).removeClass('js-active');
      });
      // remove body-class:
      $('body').removeClass('js-agenda-day-active');

      // get the item we need to open via aria-controls:
      var ariaControlsValue = $(this).attr('aria-controls');
      // -> find element:
      var zoneToOpen = $('#' + ariaControlsValue);
      // -> open the element:
      if(zoneToOpen.length > 0) {
        zoneToOpen.addClass('js-active');
        $(this).addClass('js-active');
        $('body').addClass('js-agenda-day-active');
      }

    });
  }

  function getUniqueId() {
    return Math.random().toString(36).substr(2, 16);
  }
})(jQuery);
