/** --------------------------------------------------
*
*   paragraphs
*   VIDEO LIST
*
*  -------------------------------------------------- */

(function ($) {

  $(document).ready(function () {

    // setup:
    setup();

    // check query string:
    checkQueryString();
  });

  function setup() {

    // without flickity;
    // video-button clicks:
    /* $(document).on('click', '.js-videolist-videobutton', function (e) {
      var $videoListButton = $(this);
      openVideo($videoListButton);
    }); */

    // in case flickity is enabled;
    // static click event for video items:
    $('.js-videolist-items').on('staticClick.flickity', function(event, pointer, cellElement, cellIndex) {
      event.preventDefault();

      // detect specific items/links:
      var tagName = pointer.target;
      if($(tagName).hasClass('js-videolist-videobutton')) {
        // -> open video:
        openVideo($(tagName));
      }

      // do nothing if cell was not clicked:
      if (!cellElement) {
        return;
      }
    });
  }

  function openVideo($videoListButton) {

    // get the parent item:
    var $videoItem = $videoListButton.parents('.js-videolistitem');

    // get the parent video list block:
    const $videoListBlock = $videoListButton.parents('.js-videolist');

    // get the video player zone:
    const $playerZone = $videoListBlock.find('.js-player-zone');

    // get the youtube id:
    var linkUrl = $videoListButton.attr('data-href');
    var youtubeId = getYoutubeId(linkUrl);

    // check if the player zone has a youtube id set:
    var playingYoutubeId = $playerZone.attr('data-youtubeid');

    // if the youtube id of the player zone and the new youtube
    // id are the same, we shouldn't do anything:
    if(youtubeId !== playingYoutubeId) {

      // try to stop playing videos:
      stopVideos();

      // remove existing iframes from the player zone:
      $playerZone.empty();

      // remove existing backgrounds from the player zone:
      $playerZone.css('background-image', '');

      // create youtube embed url from the youtube id:
      var ytEmbedBaseUri = "https://www.youtube.com/embed/";
      var ytEmbedPrefs = "?rel=0&modestbranding=1&autohide=1&showinfo=0&disablekb=1&autoplay=1";
      var embedUri = ytEmbedBaseUri + youtubeId + ytEmbedPrefs;

      // create the iframe element:
      const iframeEmbed = $('<iframe>', {
        src: embedUri,
        class: 'video-iframe-embed',
        frameborder: 0,
        scrolling: 'no',
        allow: 'autoplay',
        allowfullscreen: 'allowfullscreen'
      });
      iframeEmbed.appendTo($playerZone);

      // add the youtube id to the player zone:
      $playerZone.attr('data-youtubeid', youtubeId);

      // make the player zone visible, if hidden:
      $playerZone.removeAttr('hidden');

      // scroll to the player zone:
      $('html, body').stop().animate( {
        'scrollTop': $playerZone.offset().top - 30
      }, 400, 'swing', function() {
      });

      // use the video thumbnail as the background of the
      // player zone:
      var $videoImage = $videoItem.find('.field-name-field-image img');
      if($videoImage.length > 0) {
        var videoImageSrc = $videoImage.attr('src');
        if(videoImageSrc !== '') {
          $playerZone.css('background-image', 'url(' + videoImageSrc + ')');
        }
      }

      // add active class to the video list item,
      // (after first removing the active class from other items):
      $videoListBlock.find('.js-videolistitem').removeClass('js-active');
      $videoItem.addClass('js-active');
    }
  }

  function stopVideos() {

    // try to stop videos playing in iframes:
    var $iframes = $(document).find('iframe');
    $iframes.each(function(index, element) {
      var $iframe = $(element);
      var originalIframeSrc = $iframe[0].src;
      var newIframeSrc = originalIframeSrc.replace('autoplay=1', 'autoplay=0');
      $iframe[0].src = newIframeSrc;
    });
  }

  function checkQueryString() {

    // check if there is a "video" parameter in the querystring;
    // if there is, check for a videolist-item with the requested
    // video ID and open the video player;

    var queryString = window.location.search;
    var urlParameters = new URLSearchParams(queryString);
    const requestedId = urlParameters.get('video');
    if(requestedId !== null) {

      var linkToFind;

      // check if there is a videolist-item with the requested video id:
      var $videoListLinks = $(document).find('.js-videolist-videobutton[data-href*="' + requestedId + '"]');
      $videoListLinks.each(function(index, element) {
        var $videoListLink = $(element);
        var linkUrl = $videoListLink.attr('data-href');
        var youtubeId = getYoutubeId(linkUrl);
        if(youtubeId === requestedId) {
          linkToFind = $videoListLink;
        }
      });

      // if we have a result, open the video-block:
      if(linkToFind !== undefined) {
        openVideo(linkToFind);
      }
    }
  }

  /*
  * Get Youtube video ID from a URL
  * https://gist.github.com/takien/4077195
  *
  * Tested URLs:
  * var url = 'http://youtube.googleapis.com/v/4e_kz79tjb8?version=3';
  * url = 'https://www.youtube.com/watch?feature=g-vrec&v=Y1xs_xPb46M';
  * url = 'http://www.youtube.com/watch?feature=player_embedded&v=Ab25nviakcw#';
  * url = 'http://youtu.be/Ab25nviakcw';
  * url = 'http://www.youtube.com/watch?v=Ab25nviakcw';
  * url = '<iframe width="420" height="315" src="http://www.youtube.com/embed/Ab25nviakcw" frameborder="0" allowfullscreen></iframe>';
  * url = '<object width="420" height="315"><param name="movie" value="http://www.youtube-nocookie.com/v/Ab25nviakcw?version=3&amp;hl=en_US"></param><param name="allowFullScreen" value="true"></param><param name="allowscriptaccess" value="always"></param><embed src="http://www.youtube-nocookie.com/v/Ab25nviakcw?version=3&amp;hl=en_US" type="application/x-shockwave-flash" width="420" height="315" allowscriptaccess="always" allowfullscreen="true"></embed></object>';
  * url = 'http://i1.ytimg.com/vi/Ab25nviakcw/default.jpg';
  * url = 'https://www.youtube.com/watch?v=BGL22PTIOAM&feature=g-all-xit';
  * url = 'BGL22PTIOAM';
  */
  function getYoutubeId(url) {
    var ID = '';
    url = url.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    if (url[2] !== undefined) {
      ID = url[2].split(/[^0-9a-z_\-]/i);
      ID = ID[0];
    }
    else {
      ID = url;
    }
    return ID;
  }
})(jQuery);
