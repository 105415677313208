/** --------------------------------------------------
* 
*   components
*   MARQUEE
*
*  -------------------------------------------------- */

(function($){

    $(document).ready(function() {

      // setup marquee:
      setupMarquee();
    });

    function setupMarquee() {

      var allowMarquee = true;
      if(prefersReducedMotion()) {
        allowMarquee = false;
      }
      
      var $marquee = $('.wcm-marquee').marquee({
        speed: 40,
        duplicated: true,
        pauseOnHover: true,
        startVisible: true
      });

      if(!allowMarquee) {
        $marquee.marquee('pause');
      }

      $('.wmc-marquee-container').addClass('js-marquee-initialized');
    }

    function prefersReducedMotion() {
      if(typeof window.matchMedia != "undefined") {
        var hasOSReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches;
        if (hasOSReducedMotion) {
          return true;
        }
      }
      return false;
    }
})(jQuery);
