/** --------------------------------------------------
* 
*   pages
*   LAYOUT PAGE
*
*  -------------------------------------------------- */

(function ($) {

  $(window).resize(function () {

    // set background video:
    setBackgroundVideo();
  });

  Drupal.behaviors.layoutPage = {
    attach: function (context, settings) {

      // flexslider:
      $('.paragraphs-item-main-slider, .paragraphs-item-slider-group, .paragraphs-item-slider-block').each(function () {
        var currentSlider = $(this);

        if (currentSlider.find('.paragraphs-item-standard-slider-item').length > 1) {

          currentSlider.flexslider({
            selector: '.field-name-field-slider-items > .paragraphs-item-standard-slider-item',
            animation: "fade",
            easing: "swing",
            slideshow: false,
            animationSpeed: 900,
            pauseOnHover: true,
            pauseOnAction: true,
            touch: true,
            video: true,
            controlNav: true,
            directionNav: true,
            prevText: Drupal.t('Previous'),
            nextText: Drupal.t('Next'),
            animationLoop: true,
            useCSS: false,
            pausePlay: true,
            smoothHeight: false,
            start: function (slider) {
              currentSlider.addClass('slider-active');
              slider.flexslider("pause");
            }
          });
        } else {
          currentSlider.find('.paragraphs-item-standard-slider-item').addClass('js-only-one');
        }
      });

      $('.paragraphs-item-main-slider, .paragraphs-item-slider-group, .paragraphs-item-slider-block').on('inview', function (event, isInView) {
        if (isInView) {
          $(this).find('.flex-play').trigger('click');
        }
      });

      // content blocks inview:
      $('.paragraphs-items-field-blocks .field-name-field-blocks > .entity').on('inview', function (event, isInView) {
        if (isInView) {
          $(this).addClass('animate-block');
        }
      });

      // set background video:
      setBackgroundVideo();
    }
  };

  function setBackgroundVideo() {

    // cover video:
    if ($('.field-name-field-background-video').length > 0) {
      setVideoCover($('.field-name-field-background-video'));
    }
  }

  function setVideoCover($videoItem) {
    $videoItem.each(function () {
      var videoContainerWidth = $(this).outerWidth();
      var videoContainerHeight = $(this).outerHeight();

      var videoObject = $(this).find('iframe, object, video');
      var videoWidth = videoObject.outerWidth();
      var videoHeight = videoObject.outerHeight();

      var videoSetWidth = false;
      var videoSetHeight = false;

      var heightRatio = videoContainerHeight / videoHeight;
      var widthRatio = videoContainerWidth / videoWidth;

      // set width and height based on highest scale factor:
      if (widthRatio > heightRatio) {
        videoSetWidth = videoContainerWidth;
        videoSetHeight = 'auto';
      } else {
        videoSetWidth = 'auto';
        videoSetHeight = videoContainerHeight;
      }

      // set width & height:
      videoObject.css('width', videoSetWidth);
      videoObject.css('height', videoSetHeight);
    });
  }
})(jQuery);
