/** --------------------------------------------------
* 
*   pages
*   SHOWPIECE DETAIL
*
*  -------------------------------------------------- */

(function ($) {

  $(document).ready(function () {
    if (isShowpieceDetail()) {

      // setup vote links:
      setupVoteLinks();

      // setup mobile header:
      setupMobileHeader();
    }
  });

  function setupVoteLinks() {

    /*
     * we need to add a target to the vote-links,
     * to prevent them opening inside the modal/iframe
     * view 
     */

    // add "target=_parent" to links:
    var $voteLinks = $('.wcm-votes-vote-button-link');
    $voteLinks.each(function (index, element) {
      $(element).attr('target', '_parent');
    });
  }

  function setupMobileHeader() {
    // there is a different layout for mobile;
    // copy elements from the default "info block" and
    // use them to create a header for mobile;

    var $infoBlockInner = $(document).find('.showpiece-detail__info-block-inner');
    if($infoBlockInner.length > 0) {

      var $mobileHeaderblock = $('<div class="showpiece-detail__mobile-header"></div>');

      // get the previous/next links:
      // (note: prev/next are swapped in the markup)
      var prevLinkUrl = $(document).find('.wcm-imagenavigation-button--next').attr('href');
      var nextLinkUrl = $(document).find('.wcm-imagenavigation-button--previous').attr('href');

      // add the titles:
      var title1Text = '';
      var $title1 = $infoBlockInner.find('.finalist-text-teaser__title');
      if($title1.length > 0) {
        title1Text = $title1.html();
      }
      var title2Text = '';
      var $title2 = $infoBlockInner.find('.showpiece-detail__page-title');
      if($title2.length > 0) {
        title2Text = $title2.html();
      }
      var $mobileTitleBlock = $(
        '<div class="showpiece-detail__mobile-header">' +
          '<div class="showpiece-detail__mobile-title-block">' +
            '<p class="showpiece-detail__mobile-subtitle">' + title1Text + '</p>' +
            '<h1 class="showpiece-detail__mobile-title">' + title2Text + '</h1>' +
          '</div>' +
          '<div class="showpiece-detail__mobile-navigation-block">' +
            '<div class="showpiece-detail__mobile-navigation-prev">' +
              '<a href="' + prevLinkUrl + '">' + Drupal.t('Previous') + '</a>' +
            '</div>' +
            '<div class="showpiece-detail__mobile-navigation-next">' +
              '<a href="' + nextLinkUrl + '">' + Drupal.t('Next') + '</a>' +
            '</div>' +
          '</div>' +
        '</div>'
        );
      $mobileHeaderblock.append($mobileTitleBlock);

      // get the vote button:
      var $desktopVoteButton = $infoBlockInner.find('.showpiece-detail__vote-zone .wcm-votes-vote-button-container');
      if($desktopVoteButton.length > 0) {
        var desktopVoteButtonHtml = $desktopVoteButton.html();
        var $mobileVoteBlock = $('<div class="showpiece-detail__mobile-voteblock">' + desktopVoteButtonHtml + '</div>');
        $mobileHeaderblock.append($mobileVoteBlock);
      }

      // add the mobile header to the page:
      $infoBlockInner.prepend($mobileHeaderblock);
    }
  }

  function isShowpieceDetail() {
    var isShowpiecedetailPage = false;

    if ($('body').hasClass('node-type-showpiece')) {
      isShowpiecedetailPage = true;
    }

    return isShowpiecedetailPage;
  }
})(jQuery);
