/** --------------------------------------------------
*   NEWS
*  -------------------------------------------------- */

(function ($) {
  Drupal.behaviors.newsFilters = {
    attach: function (context, settings) {
      var _self = Drupal.behaviors.newsFilters;

      _self.initFilters();

      // close filter when clicking outside
      $('body').once('once-filter').bind('click', function(e) {
        var clicked_elem = e.target;
        var filterSelect = $('.show-filter');

        if (!$(clicked_elem).is('.show-filter') && !$(clicked_elem).closest('.show-filter').length) {
          filterSelect.removeClass('show-filter');
        }
      });
    },

    initFilters: function () {
      if ($('#content-filter-form-filters').length === 0) {
        return;
      }

      var _self = Drupal.behaviors.newsFilters;
      var filters = $('.form-type-checkboxes');
      var filterToggle = filters.find('> label');
      var filterOptions = filters.find('input[type="checkbox"]');

      // set a data-atribute with the original value
      filterToggle.each(function () {
        $(this).data('original', $(this).text());
      });

      // toggle options
      filterToggle.each(function () {
        var filter = $(this);

        filter.bind('click', function () {
          var wrapper = $(this).parent();
          // close all open filters
          filters.not(wrapper).removeClass('show-filter');

          wrapper.toggleClass('show-filter');
        });
      });

      // check filters on load
      filterOptions.each(function () {
        var filterItem = $(this);

        _self.filterUpdate(filterItem);
      });

      // add/remove selected items
      filterOptions.bind('change', function () {
        _self.filterUpdate($(this));
      });
    },

    // update the h4 with values
    filterUpdate: function (filterItem) {
      // check if any are checked
      var wrapper = filterItem.parents('.form-type-checkboxes');
      var updateElm = wrapper.find('> label');
      var valueSet = updateElm.text();
      var selectedVal = filterItem.parent().find('label').text();
      var original = updateElm.data('original');

      // if checked, add 
      if (filterItem.is(':checked')) {
        // if it is the first, remove the original text
        valueSet = valueSet.replace(original, '');

        // add the selected value
        if (valueSet.length === 0) {
          updateElm.text(selectedVal);
        }
        else {
          updateElm.text(valueSet + ', ' + selectedVal); 
        }				
      }
      // if not checked, see if in selector text and remove
      else {
        // is in value
        if (valueSet.indexOf(selectedVal) > -1) {
          valueSet.replace(selectedVal, '');
        }

        return;
      }

      if (updateElm.text().length === 0) {
        updateElm.text(original);
      }
    }
  };
})(jQuery);
