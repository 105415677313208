/** --------------------------------------------------
*
*   paragraphs
*   VIDEO LIST - CAROUSEL
*
*  -------------------------------------------------- */

(function ($) {

  var ALLOW_ARROW_NAVIGATION = true;
  var ALLOW_DESKTOP_MOUSE_DRAG = true;
  var isDown = false;

  $(document).ready(function () {

    // setup basic slider:
    // setupSlider();
    // or, setup flickity slider:
    setupFlickity();
  });

  function setupFlickity() {

    // initialize flickity:
    $('.js-videolist-items ').flickity({
      cellSelector: '.js-videolist-item',
      cellAlign: 'left',
      contain: true,
      prevNextButtons: true,
      pageDots: false,
      freeScroll: false,
      percentPosition: true,
      setGallerySize: true,
      resize: true,
      draggable: true,
      groupCells: true
    });
  }

  function setupSlider() {

    // process the slider navigation:

    var ticking = false;
    var hasNativeSmoothScroll = duo.Utils.testSupportsSmoothScroll;

    var $sliderBlocks = $(document).find('.js-videolist-slider-holder');
    $sliderBlocks.each(function (index, element) {
      var $sliderBlock = $(element);
      var scrollableElement = $sliderBlock.find('.js-videolist-items')[0];

      /* process slides */
      /* ************** */

      // count the number of slide items:
      var $slides = $sliderBlock.find('.js-videolist-item');
      var slidesLength = $slides.length;

      if (slidesLength > 0) {

        // add an ID to every slide item:
        var starterId = 'video-slider-' + duo.Utils.uniqueId;
        $slides.each(function (i, e) {
          var $slide = $(e);
          $slide.attr('id', starterId + '-' + i);
        });

        /* slider navigation */
        /* ***************** */

        // setup arrow navigation:
        if (ALLOW_ARROW_NAVIGATION) {

          // create a navigation zone:
          let $navZone = $('<nav class="news-slider-nav"></nav>');

          // navigation arrows:
          let $sliderPrevBtn = $('<button>', {
            'class': 'news-slider-nav__btn news-slider-nav__btn--prev',
            'type': 'button',
            html: '<svg width="10" height="10" viewBox="0 0 10 10" aria-hidden="true" focusable="false"><path d="M8 9.5v-9L2 5z" /></svg>' +
              `<span class="visually-hidden">${Drupal.t('previous')}</span>`
          });
          let $sliderNextBtn = $('<button>', {
            'class': 'news-slider-nav__btn news-slider-nav__btn--next',
            'type': 'button',
            html: '<svg width="10" height="10" viewBox="0 0 10 10" aria-hidden="true" focusable="false"><path d="M8 5 2 .5v9z" /></svg>' +
              `<span class="visually-hidden">${Drupal.t('next')}</span>`
          });
          let $sliderNav = $('<div class="news-slider-nav-arrows js-news-slider-nav-arrows">').append($sliderPrevBtn, $sliderNextBtn);
          $navZone.append($sliderNav);

          // arrow events:

          $sliderPrevBtn.on('click', function () {
            const button_is_disabled = $(this).attr('aria-disabled');
            if (button_is_disabled === undefined) {
              if (!$sliderPrevBtn.hasClass('js-busy')) {

                // -> scroll a bit:
                const scrollTarget = scrollableElement.scrollLeft - scrollableElement.clientWidth / 1.5;
                duo.Utils.smoothScroll(scrollableElement, scrollTarget, true, hasNativeSmoothScroll);

                // -> prevent "fast clicks" on the button:
                $sliderPrevBtn.addClass('js-busy');
                setTimeout(function () {
                  $sliderPrevBtn.removeClass('js-busy');
                }, 350);
              }
            }
          });

          $sliderNextBtn.on('click', function () {
            const button_is_disabled = $(this).attr('aria-disabled');
            if (button_is_disabled === undefined) {
              if (!$sliderNextBtn.hasClass('js-busy')) {

                // -> scroll a bit:
                const scrollTarget = scrollableElement.scrollLeft + scrollableElement.clientWidth / 1.5;
                duo.Utils.smoothScroll(scrollableElement, scrollTarget, true, hasNativeSmoothScroll);

                // -> prevent "fast clicks" on the button:
                $sliderNextBtn.addClass('js-busy');
                setTimeout(function () {
                  $sliderNextBtn.removeClass('js-busy');
                }, 350);
              }
            }
          });

          // add navzone to block:
          $sliderBlock.append($navZone);

          // check navigation button states:
          checkButtonStates(scrollableElement);

          // navigation events:
          scrollableElement.addEventListener('scroll', (e) => {
            if (!ticking) {
              window.requestAnimationFrame(function () {

                // update navigation buttons:
                checkButtonStates(scrollableElement);

                ticking = false;
              });
              ticking = true;
            }
          });

          // check for navigation visibility:
          checkNavVisibility(scrollableElement);
          const timeoutId = 'newsslider' + duo.Utils.uniqueId;
          let timeout = false;
          $(window).once(`newsslider-${timeoutId}`).on(`resize.${timeoutId}`, function () {
            clearTimeout(timeout);
            timeout = setTimeout(checkNavVisibility(scrollableElement), 250);
          });
        }

        // setup dragging:
        if (ALLOW_DESKTOP_MOUSE_DRAG) {
          // only if there is no touch support:
          if (!duo.Utils.hasTouchSupport()) {
            _handleMouseDrag(scrollableElement);
          }
        }
      }
    });
  }

  function checkButtonStates(sliderInstance) {
    const $parentBlock = $(sliderInstance).parents('.js-videolist-slider-holder');

    const clientWidth = sliderInstance.clientWidth;
    const sliderWidth = sliderInstance.scrollWidth;
    const maxScroll = sliderWidth - clientWidth;
    const currentScroll = sliderInstance.scrollLeft;
    const $sliderPrev = $parentBlock.find('.news-slider-nav__btn--prev');
    const $sliderNext = $parentBlock.find('.news-slider-nav__btn--next');

    // allow the scroll to be a few pixels off,
    // since the browser might not precisely scroll
    // to the "0" or "maxScroll" positions:
    const allowance = 5;

    if (currentScroll <= allowance) {
      $sliderPrev.attr('aria-disabled', 'true');
    } else {
      $sliderPrev.removeAttr('aria-disabled');
    }

    if (currentScroll >= maxScroll - allowance) {
      $sliderNext.attr('aria-disabled', 'true');
    } else {
      $sliderNext.removeAttr('aria-disabled');
    }
  }

  function checkNavVisibility(sliderInstance) {
    const $sliderInstance = $(sliderInstance);
    const $parentBlock = $sliderInstance.parents('.js-videolist-slider-holder');
    const $navArrowsBlock = $parentBlock.find('.js-news-slider-nav-arrows');

    const clientWidth = sliderInstance.clientWidth;
    const sliderWidth = sliderInstance.scrollWidth;

    if (sliderWidth > clientWidth) {
      $sliderInstance.addClass('js-overflow');
      $navArrowsBlock.addClass('js-visible');
    } else {
      $sliderInstance.removeClass('js-overflow');
      $navArrowsBlock.removeClass('js-visible');
    }

    // check button states:
    checkButtonStates(sliderInstance);
  }

  function _handleMouseDrag(sliderInstance) {
    let startX;
    let scrollLeft;
    const scrollableElement = sliderInstance;

    if (scrollableElement) {

      scrollableElement.addEventListener('mousedown', (e) => {
        isDown = true;
        scrollableElement.classList.add('js-mouse-down');
        startX = e.pageX - scrollableElement.offsetLeft;
        scrollLeft = scrollableElement.scrollLeft;
      });

      scrollableElement.addEventListener('mouseleave', () => {
        isDown = false;
        scrollableElement.classList.remove('js-active-drag');
        scrollableElement.classList.remove('js-mouse-down');
        scrollableElement.classList.remove('js-about-to-drag');
      });

      scrollableElement.addEventListener('mouseup', () => {
        isDown = false;
        scrollableElement.classList.remove('js-active-drag');
        scrollableElement.classList.remove('js-mouse-down');
        scrollableElement.classList.remove('js-about-to-drag');
      });

      scrollableElement.addEventListener('mousemove', (e) => {
        if (!isDown) return;
        e.preventDefault();

        const x = e.pageX - scrollableElement.offsetLeft;
        const walk = (x - startX) * 1;
        scrollableElement.scrollLeft = scrollLeft - walk;

        if (walk > 5) {
          scrollableElement.classList.add('js-active-drag');
        } else if (walk < -5) {
          scrollableElement.classList.add('js-active-drag');
        }
      });
    }

    $(sliderInstance).addClass('js-drag-enabled');
  }
})(jQuery);
