/** --------------------------------------------------
* 
*   components
*   MODAL
*
*  -------------------------------------------------- */

(function($){

  $(document).ready(function() {

    var windowWidth = $(window).width();

    // set class on body if the width of the page
    // is not large enough for modals;
    checkModalWindowSize(windowWidth);

    // -> also do this on resize:
    //    but only on width-resize;

    var modalresizeTimer;
    $(window).on('resize.ModalCheck', function(e) {

      clearTimeout(modalresizeTimer);
      modalresizeTimer = setTimeout(function() {

        // don't do anything if the width is the same:
        if ($(window).width() === windowWidth) return;
        // else:
        windowWidth = $(window).width();
        checkModalWindowSize(windowWidth);
      }, 500);
    });

    // open links that contain #popup in a popup:
    $(document).on('click', 'a[href*="#popup"]', function(e) {
      e.preventDefault();

      // -> get the original url:
      var url = $(this).attr('href');

      // -> remove hash:
      url = url.substring(0, url.indexOf('#'));

      // -> add url parameter:
      if (url.indexOf('?') == -1) {
        url += '?popup=true';
      } else {
        url += '&popup=true';
      }

      // -> avoid nested popups:
      var inIframe = (window.self !== window.top);
      if (inIframe) {
        // --> open link in the iframe:
        window.location.href = url;
      } else {

        // -> open link in popup,
        //    or open link in the same window
        //    (because we disable modal-windows
        //    on some devices)

        // check if we should use a modal:
        // -------------------------------

        var useModal = true;

        // -> detect user agents:
        var auparser = new UAParser();
        var auResult =  auparser.getResult();
        var browsername = auResult.browser.name;
        // --> no modals on "Mobile Safari":
        if(browsername === 'Mobile Safari') {
          useModal = false;
        }
        // --> test: no modals on "Chrome":
        // if(browsername === 'Chrome') {
        // 	useModal = false;
        // }

        // -> detect width:
        if($('body').hasClass('disable-modals')) {
          useModal = false;
        }

        // open link/modal:
        // ----------------

        if(useModal) {

          $.magnificPopup.open({
            items: {
              src: url
            },
            type: 'iframe',
            removalDelay: 1000,
            mainClass: 'mfp-fade',
            fixedContentPos: true,
            fixedBgPos: true,
            callbacks: {
              open: function() {
                $.magnificPopup.instance.close = function() {
                  // -> call the original close method to close the popup:
                  $.magnificPopup.proto.close.call(this);
                };
              },
              close: function() {
                // attach drupal behaviors:
                // Drupal.attachBehaviors($('#container'));
              }
            }
          });

        } else {
          // -> in new window: window.open(url, '_blank');
          // -> open link in the same window/tab:
          // url = url.replace("popup=true", "popup=false");
          url = url.replace("popup=true", "");
          location.href = url;
        }

      }
    });
  });

  function checkModalWindowSize(windowWidth) {
    var $body = $('body');
    $body.removeClass('disable-modals');
    if(windowWidth < 980) {
      $body.addClass('disable-modals');
    }
  }

  Drupal.behaviors.setModal = {
    attach: function (context, settings) {

      // check popup-mode + keep popup-mode when already in popup-mode:
      var inPopupMode = (window.location.href.indexOf('popup=true') != -1);
      if (inPopupMode) {
        // -> check links, but exclude some:
        $('a', context).not('a[href*="#popup"]').not('a[href*="popup=true"]').not('a[target="_blank"]').not('a[target="_parent"]').not('.contextual-links-wrapper a').not('.wcm-votes-vote-button-link').once(function() {
          $(this).click(function(e) {
            e.preventDefault();
            var url = $(this).attr('href');
            url = updateUrlParameter(url, 'popup', 'true');
            window.location.href = url;
          });
        });
      }
    }
  };

  function updateUrlParameter(uri, key, value) {
    // remove the hash part before operating on the uri
    var i = uri.indexOf('#');
    var hash = i === -1 ? ''  : uri.substr(i);
    uri = i === -1 ? uri : uri.substr(0, i);

    var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    var separator = uri.indexOf('?') !== -1 ? "&" : "?";

    if (!value) {
      // remove key-value pair if value is empty
      uri = uri.replace(new RegExp("([?&]?)" + key + "=[^&]*", "i"), '');
      if (uri.slice(-1) === '?') {
      uri = uri.slice(0, -1);
      }
      // replace first occurrence of & by ? if no ? is present
      if (uri.indexOf('?') === -1) uri = uri.replace(/&/, '?');
    } else if (uri.match(re)) {
      uri = uri.replace(re, '$1' + key + "=" + value + '$2');
    } else {
      uri = uri + separator + key + "=" + value;
    }
    return uri + hash;
  }
})(jQuery);
