/** --------------------------------------------------
*   LOADING
*  -------------------------------------------------- */

(function ($) {

  $(document).ready(function () {

    // Load more button
    $(document).on('click', '.callebaut-productdb-load-more', function (e) {
      $(this).addClass('loading');
    });
  });

  $(window).load(function () {

    // When the page is loaded
    $('html').addClass('page-loaded');
  });

  // Overview load animation
  Drupal.behaviors.overviewLoadAnimation = {
    attach: function (context, settings) {

      smoothLoadAnimation('.search-results-content', '.callebaut-productdb-product-block-teaser', context);
      smoothLoadAnimation('.search-results-content', '.callebaut-productdb-recipe-block-teaser', context);
      smoothLoadAnimation('.product-productgroup-wrapper', '.callebaut-productdb-product-list-teaser', context);
      smoothLoadAnimation('.productdb-group-wrapper', '.callebaut-productdb-recipe-list-teaser', context);
      smoothLoadAnimation('.productdb-group-wrapper', '.callebaut-productdb-chocolate-academy-course-list-teaser', context);
      smoothLoadAnimation('.related-products-items', '.callebaut-productdb-product-related-teaser', context);
      smoothLoadAnimation('.related-recipes-items', '.callebaut-productdb-recipe-related-teaser', context);
      smoothLoadAnimation('.related-courses-items', '.callebaut-productdb-chocolate-academy-course-related-teaser', context);
      smoothLoadAnimation('.related-videos-items', '.node-video.node-teaser', context);
      smoothLoadAnimation('.paragraphs-item-productdb-group', '.callebaut-productdb-product-related-teaser', context);
      smoothLoadAnimation('.paragraphs-item-productdb-group', '.callebaut-productdb-recipe-related-teaser', context);
      smoothLoadAnimation('.paragraphs-item-productdb-group', '.callebaut-productdb-chocolate-academy-course-related-teaser', context);
    }
  };

  function smoothLoadAnimation($wrapper, $item, $context) {
    var defaultSpeed = 250;
    var defaultSpeelChange = 0.8;

    if ($($item).length > 0) {
      $($context).find($wrapper).each(function () {
        $(this).find($item).not(".visible").each(function (i) {
          i = (i * defaultSpeelChange) + 1;
          var speed = i * defaultSpeed;

          var currentItem = $(this);
          setTimeout(function () {
            currentItem.addClass('visible');
          }, speed);
        });
      });
    }
  }
})(jQuery);
