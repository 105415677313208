/** --------------------------------------------------
*   USER
*  -------------------------------------------------- */

(function($){

  $(document).ready(function() {

    // setup register form:
    setupRegisterForm();

    // setup login form:
    setupLoginForm();
  });

  function setupRegisterForm() {
    var $registerForm = $(document).find('#user-register-form');
    if($registerForm.length > 0) {

      // wrap the form-content in a container:
      $registerForm.wrapInner('<div class="register-form-inner"></div>');

      // add a title to the content-block:
      var $registerTitle = $('<h2 class="register-form-inner__title">' + Drupal.t('Register') + '</h2>');
      var $innerWrapper = $(document).find('.register-form-inner');
      $innerWrapper.prepend($registerTitle);

      // move hybridauth and register-container:
      var $loginBlock = $('<div class="register-form-login"></div>');

      var $hybridauth = $registerForm.find('.hybridauth-widget-wrapper');
      var $registerContainer = $registerForm.find('.register-container');

      $hybridauth.appendTo($loginBlock);
      $registerContainer.appendTo($loginBlock);

      $loginBlock.appendTo($registerForm);
    }
  }

  function setupLoginForm() {
    var $loginForm = $(document).find('#user-login');
    if($loginForm.length > 0) {

       // wrap the form-content in a container:
       $loginForm.wrapInner('<div class="login-form-inner"></div>');

       // add a title to the content-block:
       var $loginTitle = $('<h2 class="login-form-inner__title">' + Drupal.t('Login') + '</h2>');
       var $innerWrapper = $(document).find('.login-form-inner');
       $innerWrapper.prepend($loginTitle);

       // add a title to the "register" block:
       var $registerTitle = $('<h2 class="register-container__register-title">' + Drupal.t('Register') + '</h2>');
       var $registerWrapper = $(document).find('.register-container ');
       $registerWrapper.prepend($registerTitle);
    }
  }
})(jQuery);
