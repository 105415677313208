/** --------------------------------------------------
*   MATCH HEIGHT
*  -------------------------------------------------- */

(function($){
  Drupal.behaviors.match = {
    attach: function (context, settings) {
      var _self = Drupal.behaviors.match;

      $('body').bind('selection-loaded', function () {
        _self.finalists();
      });
    },

    finalists: function () {
      var $elmWrap = $('.meet-the-finalists .key-dates-items');
      var $elm = $elmWrap.find('> .view-mode-teaser');

      $elm.matchHeight({
        'property': 'height',
        'byRow': false
      });

      $elmWrap.css('height', $elmWrap.height() + 240); // Last element wasn't fully visible (still no idea why)
    }
  };
})(jQuery);
